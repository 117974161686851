.link-group {
    width: 50%;
    margin-top: 1.25em;
    padding-left: 0 !important;
    padding-right: 0 !important;
}

.link-group h6 {
    font-size: 20px;
}

.links-row {
    border-bottom: solid 1px white;
    padding: 18px 0 35px 10px;
}

.links-row a {
    font-size: 16px;
    font-weight: 500;
    color: white;
}

.links-row a:hover {
    text-decoration: underline;
}

.links-row h6 {
    font-weight: bold;
    font-size: 24px;
    margin: 0 0 10px 0;
}

.sup {
    font-size: 45%;
    top: -10px;
}

@media screen and (min-width: 768px) {
    .button-row {
        max-width: 1024px;
    }

    .links-row {
        margin: 0 auto;
    }

    .base-row {
        margin: 0 auto;
    }

    .copyright {
        max-width: 1024px;
    }
}

@media screen and (max-width: 640px) {
    .button-row {
        padding: 17px 0;
    }

    .links-row {
        border: none;
        border-top: solid 1px #4a5168;
        padding-top: 25px;
        padding-left: 1rem;
        padding-bottom: 0;
    }

    .links-row h6 {
        font-size: 20px;
    }
}

.links-column {
    display: grid;
    column-gap: 8rem;
    min-width: 280px;
    box-sizing: border-box;
}

@media screen and (min-width: 768px) {
    .links-column {
        grid-template-columns: max-content max-content;
    }
}

.links-single-column {
    display: grid;
    column-gap: 1.5rem;
    min-width: 280px;
    box-sizing: border-box;
}

.links-row-ftdr {
    border: none;
    padding-left: 0.4rem;
    padding-top: 1.9rem;
}

.links-row-ftdr h6,
.links-row-ftdr a {
    color: #282828;
}

.links-row-ftdr h6 {
    font-size: 1.12rem;
    margin-bottom: 1.9rem;
}

.links-row-ftdr a {
    font-size: 0.8rem;
    line-height: 28px;
    letter-spacing: -0.1px;
}

.button-row-ftdr {
    padding-top: 8px;
}

.footer-ftdr .phone-ftdr {
    color: #282828;
    font-size: 1rem;
}